import { ActionTypes } from 'state/action-types';
import { Action } from 'state/actions';
import { Dispatch } from 'redux';
import agent from 'api';
import { toast } from 'react-toastify';

export const getOrganizations = (organizationId: number) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_ORGANIZATIONS_LOADING,
      loading: true,
    });

    let Organizations = null;
    try {
      Organizations = await agent.Organizations.list(organizationId);
    } catch (error) {
      toast.error('An error occurred.');
    }
    dispatch({
      type: ActionTypes.SET_ORGANIZATIONS,
      payload: {
        Organizations,
      },
    });
  };
};

export const getParentOrganizations = (organizationId: number) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_ORGANIZATIONS_LOADING,
      loading: true,
    });

    let ParentOrganizations = null;
    try {
      ParentOrganizations = await agent.Organizations.getParentOrg(
        organizationId,
      );
    } catch (error) {
      toast.error('An error occurred.');
    }

    dispatch({
      type: ActionTypes.SET_PARENT_ORGANIZATIONS,
      payload: {
        ParentOrganizations,
      },
    });
  };
};

export const getConfigdata = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_ORGANIZATIONS_LOADING,
      loading: true,
    });

    let configdata = null;
    try {
      configdata = await agent.Organizations.configdata();
    } catch (error) {
      toast.error('An error occurred.');
    }

    dispatch({
      type: ActionTypes.SET_CONFIG_DATA,
      payload: {
        configdata,
      },
    });
  };
};
