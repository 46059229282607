interface Props {
  title: string;
}

const ReportToolIcon = ({ title }: Props) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon/report-tool" fill="#050037">
          <path
            d="M4,6 L20,6 L20,2 L4,2 L4,6 Z M4,12 L10,12 L10,8 L4,8 L4,12 Z M4,18 L15,18 L15,14 L4,14 L4,18 Z M0,20 L2,20 L2,0 L0,0 L0,20 Z"
            id="alignment_left-[#853]"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export { ReportToolIcon };
