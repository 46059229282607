import { request } from './index';
import { SvarUT as SUT, ActionLog } from 'models';

const SvarUT = {
  getSvarUT: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
  ) =>
    request.get<SUT[] | null>(
      `SvarUtRespondent?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
    ),
  getSvarUTActionLog: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
  ) =>
    request.get<ActionLog[] | null>(
      `SvarUtRespondent/getactionlog?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
    ),
};

export default SvarUT;
