import { OrganizationSurvey } from 'models';
import { Action } from 'state/actions';
import { ActionTypes } from 'state/action-types';

interface OrganizationSurveyState {
  loading: boolean;
  error: string | null;
  data: {
    OrganizationSurvey: OrganizationSurvey | null;
  };
}

const initialState: OrganizationSurveyState = {
  loading: false,
  error: null,
  data: {
    OrganizationSurvey: null,
  },
};

const reducer = (
  state: OrganizationSurveyState = initialState,
  action: Action,
): OrganizationSurveyState => {
  switch (action.type) {
    case ActionTypes.SET_ORGANIZATION_SURVEY:
      return {
        ...state,
        loading: false,
        data: {
          OrganizationSurvey: action.payload.OrganizationSurvey,
        },
      };
    case ActionTypes.UPDATE_ORGANIZATION_SURVEY:
      return {
        ...state,
        loading: false,
        data: {
          OrganizationSurvey: action.payload.OrganizationSurveyUpdate
            ?.responseData
            ? action.payload.OrganizationSurveyUpdate.responseData
            : null,
        },
      };
    case ActionTypes.SET_ORGANIZATION_SURVEY_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default reducer;
