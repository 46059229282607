import './index.scss';

const UnauthorizedPage = () => {
  return (
    <div className="page-not-found">
      <h1>401 Unauthorized</h1>
    </div>
  );
};
const NotFoundPage = () => (
  <div className="page-not-found">
    <h1>404 Page NotFound</h1>
  </div>
);

export { NotFoundPage, UnauthorizedPage };
