import axios, { AxiosResponse } from 'axios';
//import { setupCache } from 'axios-cache-adapter';
import localForage from 'localforage';
import { User } from 'models';
import Emails from './email';
import Login from './login';
import Organizations from './organizations';
import OrganizationSurvey from './organizationSurvey';
import Survey from './survey';
import SvarUT from './svarUT';
import Tickets from './tickets';
import SvarUtRespondent from './svarUtRespondent';
import Wizard from './wizard';
import { toast } from 'react-toastify';

// const cache = setupCache({
//   maxAge: 60 * 60 * 1000,
//   exclude: {
//     methods: ['put', 'patch', 'delete'],
//     query: false,
//   },
// });

const instance = axios.create({
  //adapter: cache.adapter,
  // eslint-disable-next-line no-undef
  baseURL: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BASE_API_VERSION}/`,
});

instance.interceptors.request.use(
  async (config) => {
    const User = await localForage.getItem<User>('User');
    config.headers = {
      Authorization: `Bearer ${User?.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    return config;
  },
  (error) => {
    console.log('error, ', error);
    toast.error('An error occurred.');
    Promise.reject(error);
  },
);

instance.interceptors.response.use(async (response) => {
  try {
    return response;
  } catch (error) {
    toast.error('An error occurred.');
    return await Promise.reject(error);
  }
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export const request = {
  get: <T>(url: string) => instance.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    instance.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) =>
    instance.put<T>(url, body).then(responseBody),
};

const agent = {
  Login,
  Organizations,
  OrganizationSurvey,
  Survey,
  Emails,
  SvarUT,
  Tickets,
  SvarUtRespondent,
  Wizard,
};

export default agent;
