import axios from 'axios';
import { User } from 'models';

const Login = {
  getToken: async (token: string) => {
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    };
    const responce = await axios.post<User>(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BASE_API_VERSION}/User/Login`,
      {
        userId: 0,
        firstName: 'string',
        lastName: 'string',
        email: 'string',
        accessLevel: 'string',
        organizationId: 0,
        organizationRole: 'string',
        token: `${token}`,
        password: 'string',
      },
      axiosConfig,
    );
    return responce.data;
  },
};

export default Login;
