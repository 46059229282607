import { ActionTypes } from 'state/action-types';
import { Action } from 'state/actions';
import { UrlQuery } from 'models';
import { Dispatch } from 'redux';
import agent from 'api';
import { toast } from 'react-toastify';

export const setEmailLoading = (loading: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_EMAIL_LOADING,
      loading: loading,
    });
  };
};

export const resetEmailS = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.RESET_EMAIL,
    });
  };
};

export const setEmailS = (urlQuery: UrlQuery) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_EMAIL_LOADING,
      loading: true,
    });
    try {
      const Emails = await agent.Emails.getEmails(
        urlQuery.selectedOrgUnitId,
        urlQuery.wizardId,
        urlQuery.resultSetGroupId,
      );
      dispatch({
        type: ActionTypes.SET_EMAILS,
        payload: {
          Emails,
        },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_EMAILS,
        payload: {
          Emails: null,
        },
      });
      toast.error('An error occurred.');
    }
  };
};

export const setEmailActionLog = (urlQuery: UrlQuery) => {
  return async (dispatch: Dispatch<Action>) => {
    let EmailActionLog = null;
    try {
      EmailActionLog = await agent.Emails.getEmailActionLog(
        urlQuery.selectedOrgUnitId,
        urlQuery.wizardId,
        urlQuery.resultSetGroupId,
      );
    } catch (error) {
      toast.error('An error occurred.');
    }

    dispatch({
      type: ActionTypes.SET_EMAILS_ACTION_LOG,
      payload: {
        EmailActionLog,
      },
    });
  };
};
