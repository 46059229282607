interface Props {
  title: string;
}

const NewTabIcon = ({ title }: Props) => {
  return (
    <svg
      width="10px"
      height="10px"
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="BK-Undersøkelser/Rapport-vis-expanded"
          transform="translate(-554.000000, -619.000000)"
          fill="#050037"
        >
          <g id="Group-12-Copy-2" transform="translate(538.000000, 608.000000)">
            <g id="Group-5-Copy" transform="translate(16.000000, 11.000000)">
              <polygon
                id="Fill-1"
                points="0 0.0001 0 10.0001 10 10.0001 10 8.0001 2 8.0001 2 0.0001"
              ></polygon>
              <polygon id="Fill-2" points="4 2 10 2 10 0 4 0"></polygon>
              <polygon id="Fill-3" points="8 6 10 6 10 0 8 0"></polygon>
              <polygon
                id="Fill-4"
                points="3.303 5.3083 4.717 6.7233 9.248 2.1913 7.834 0.7773"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { NewTabIcon };
