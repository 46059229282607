const Calendar = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>calendar [#1195]</title>
      <g id="GD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="KIT"
          transform="translate(-121.000000, -3013.000000)"
          fill="#000000"
        >
          <path
            d="M130,3024 L128,3024 L128,3022 L132,3022 L132,3028 L130,3028 L130,3024 Z M123,3031 L139,3031 L139,3019 L123,3019 L123,3031 Z M139,3015 L139,3013 L137,3013 L137,3015 L132,3015 L132,3013 L130,3013 L130,3015 L125,3015 L125,3013 L123,3013 L123,3015 L121,3015 L121,3033 L141,3033 L141,3015 L139,3015 Z"
            id="calendar-[#1195]"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export { Calendar };
