import { combineReducers } from 'redux';
import UserReducer from './userReducer';
import OrganizationSurveyReducer from './organizationSurveyReducer';
import SurveyReducer from './surveyReducer';
import UrlQueryReducer from './urlQueryReducer';
import OrganizationsReducer from './organizationsReducer';
import EmailsReducer from './emailsReducer';
import SvarUTReducer from './svarut';
import TicketsReducer from './ticketsReducer';

const reducers = combineReducers({
  User: UserReducer,
  OrganizationSurvey: OrganizationSurveyReducer,
  Survey: SurveyReducer,
  UrlQuery: UrlQueryReducer,
  Organizations: OrganizationsReducer,
  Emails: EmailsReducer,
  SvarUT: SvarUTReducer,
  Tickets: TicketsReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
