import { request } from './index';
import {
  OrganizationSurvey as OrgSurvey,
  OrganizationSurveyUpdate,
} from 'models';

const OrganizationSurvey = {
  get: (organizationId: number, wizardId: number, resultsetGroupId: number) =>
    request.get<OrgSurvey | null>(
      `OrganizationSurvey?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
    ),
  updateproperty: (propertyName: string, body: OrgSurvey) =>
    request.put<OrganizationSurveyUpdate | null>(
      `OrganizationSurvey?propertyName=${propertyName}`,
      body,
    ),
  getSurveys: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
    language: string,
  ) =>
    request.get<OrgSurvey[] | null>(
      `OrganizationSurvey/OrganizationSurveys?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}&language=${language}`,
    ),
  IsValidSchedulingTime: (propertyname: string) =>
    request.get<boolean>(
      `OrganizationSurvey/IsValidSchedulingTime?propertyname=${propertyname}`,
    ),
};

export default OrganizationSurvey;
