import Multiselect from 'multiselect-react-dropdown';
import './index.scss';

export interface DropdownItem {
  value: string | number;
  label: string | number;
  selected?: boolean;
  data?: any;
}

interface Props {
  id?: string;
  label?: string;
  value?: string | number;
  onChange: (value: string) => void;
  items: DropdownItem[];
}
const Dropdown = ({
  id = 'dropdown',
  label,
  value,
  onChange,
  items,
}: Props) => {
  return (
    <>
      {label && (
        <label className="drop-down-label" htmlFor={id}>
          {label}
        </label>
      )}
      <select
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className="drop-down"
        name={id}
        id={id}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value} selected={item?.selected}>
            {item.label}
          </option>
        ))}
      </select>
    </>
  );
};

export interface MultiselectIitem {
  name: string;
  id: number | string;
}

interface MultiselectProps {
  options: MultiselectIitem[];
  selectedValue?: MultiselectIitem[];
  onSelect?: (selectedList: any, selectedItem: any) => void;
  onRemove?: (selectedList: any, selectedItem: any) => void;
  displayValue?: string;
  placeholder?: string;
  emptyRecordMsg?: string;
  style?: object;
}

const MultiDropDown = ({
  options,
  selectedValue,
  onSelect,
  onRemove,
  displayValue = 'name',
  placeholder,
  emptyRecordMsg,
  style,
}: MultiselectProps) => {
  return (
    <Multiselect
      options={options}
      selectedValues={selectedValue}
      onSelect={onSelect}
      onRemove={onRemove}
      displayValue={displayValue}
      placeholder={placeholder}
      emptyRecordMsg={emptyRecordMsg}
      style={style}
      avoidHighlightFirstOption
    />
  );
};

export { Dropdown, MultiDropDown };
