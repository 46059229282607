import { useActions, useTypedSelector } from 'hooks';
import { UrlQuery, User } from 'models';
import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';
import localForage from 'localforage';
import { Logo } from 'components/UI';

const Login = () => {
  const location = useLocation().search;
  const { logIn, setUrlQuery } = useActions();
  const UserLoggedIn = useTypedSelector(({ User: { data } }) => data.User);

  const userLogin = async () => {
    const query = new URLSearchParams(location);
    const userToken = query.get('userToken');
    const surveyId = query.get('surveyId');
    const rootOrgUnitId = query.get('rootOrgUnitId');
    const selectedOrgUnitId = query.get('selectedOrgUnitId');
    const selectedSurveyRootOrgUnitId = query.get(
      'selectedSurveyRootOrgUnitId',
    );
    const wizardId = surveyId?.split('_')[0];
    const resultSetGroupId = surveyId?.split('_')[1];
    const sessionid = query.get('sessionid');

    let UrlQuery: UrlQuery | null;
    let User: User | null;
    if (
      userToken &&
      surveyId &&
      rootOrgUnitId &&
      selectedOrgUnitId &&
      selectedSurveyRootOrgUnitId &&
      wizardId &&
      resultSetGroupId &&
      sessionid
    ) {
      UrlQuery = {
        surveyId,
        rootOrgUnitId: parseInt(rootOrgUnitId),
        selectedOrgUnitId: parseInt(selectedOrgUnitId),
        selectedSurveyRootOrgUnitId: parseInt(selectedSurveyRootOrgUnitId),
        wizardId: parseInt(wizardId),
        resultSetGroupId: parseInt(resultSetGroupId),
        sessionid,
        bk_token: userToken,
      };
      User = null;
      // document.cookie =
      //   'JSESSIONID=' +
      //   sessionid +
      //   ';expires=Session' +
      //   ';samesite=none;secure';
    } else {
      UrlQuery = await localForage.getItem<UrlQuery>('UrlQuery');
      User = await localForage.getItem<User>('User');
    }

    logIn(User, userToken);
    setUrlQuery(UrlQuery);
  };

  useEffect(() => {
    userLogin();
  }, []);

  return (
    <>
      {UserLoggedIn?.token && <Redirect to="/respondents" />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          minHeight: '100vh',
          fontSize: 'xxx-large',
          fontWeight: 'bolder',
          opacity: '.5',
          backgroundColor: '#fff',
        }}
      >
        <Logo
          style={{
            width: '225px',
            height: '100%',
            margin: 'auto',
          }}
        />
      </div>
    </>
  );
};

export default Login;
