export enum ActionTypes {
  SET_ORGANIZATION_SURVEY = 'SET_ORGANIZATION_SURVEY',
  UPDATE_ORGANIZATION_SURVEY = 'UPDATE_ORGANIZATION_SURVEY',
  SET_SURVEY = 'SET_SURVEY',
  LOGIN = 'LOGIN',
  SET_URL_QUERY = 'SET_URL_QUERY',
  SET_ORGANIZATIONS = 'SET_ORGANIZATIONS',
  SET_PARENT_ORGANIZATIONS = 'SET_PARENT_ORGANIZATIONS',
  SET_CONFIG_DATA = 'SET_CONFIG_DATA',
  SET_EMAILS = 'SET_EMAILS',
  RESET_EMAIL = 'RESET_EMAIL',
  SET_EMAILS_ACTION_LOG = 'SET_EMAILS_ACTION_LOG',
  SET_SVAR_UT = 'SET_SVAR_UT',
  RESET_SVAR_UT = 'RESET_SVAR_UT',
  SET_SVAR_UT_ACTION_LOG = 'SET_SVAR_UT_ACTION_LOG',
  SET_TICKETS = 'SET_TICKETS',
  RESET_TICKETS = 'RESET_TICKETS',
  SET_EMAIL_LOADING = 'SET_EMAIL_LOADING',
  SET_SVAR_UT_LOADING = 'SET_SVAR_UT_LOADING',
  SET_TICKETS_LOADING = 'SET_TICKETS_LOADING',
  SET_ORGANIZATIONS_LOADING = 'SET_ORGANIZATIONS_LOADING',
  SET_ORGANIZATION_SURVEY_LOADING = 'SET_ORGANIZATION_SURVEY_LOADING',
}
