interface Props {
  title: string;
}

const DoneMiniIcon = ({ title }: Props) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <path
        fill="#050037"
        fillRule="evenodd"
        d="M20 3.2L7.694 16l-.002-.002V16L0 8l3.076-3.2 4.616 4.8L16.924 0z"
      />
    </svg>
  );
};

export { DoneMiniIcon };
