import { UrlQuery } from 'models';
import { Action } from 'state/actions';
import { ActionTypes } from 'state/action-types';

interface UrlQueryState {
  loading: boolean;
  error: string | null;
  data: {
    UrlQuery: UrlQuery | null;
  };
}

const initialState: UrlQueryState = {
  loading: false,
  error: null,
  data: {
    UrlQuery: null,
  },
};

const reducer = (
  state: UrlQueryState = initialState,
  action: Action,
): UrlQueryState => {
  switch (action.type) {
    case ActionTypes.SET_URL_QUERY:
      return {
        ...state,
        data: {
          UrlQuery: action.payload.UrlQuery,
        },
      };
    default:
      return state;
  }
};

export default reducer;
