import { request } from './index';
import {
  Emails as EM,
  ActionLog,
  DeleteBatch,
  EmailImport,
  SendEmailSmsResponse,
  PrkUserGroup,
} from 'models';

const Emails = {
  getEmails: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
  ) =>
    request.get<EM[] | null>(
      `Email?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
    ),
  getEmailActionLog: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
  ) =>
    request.get<ActionLog[] | null>(
      `Email/GetEmailActionLog?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
    ),
  SendEmailOrSms: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
    messagetype: string,
    transportType: string,
    body: string[],
  ) =>
    request.post<SendEmailSmsResponse>(
      `Email/SendEmailOrSms?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}&messagetype=${messagetype}&transportType=${transportType}`,
      body,
    ),
  Create: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
    body: { emailAddress: string; mobile: string },
  ) =>
    request.post<EM>(
      `email/Create?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
      body,
    ),
  DeleteBatch: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
    isSvarUt: boolean,
    body: string[],
  ) =>
    request.post<DeleteBatch>(
      `email/DeleteBatch?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}&isSvarUt=${isSvarUt}`,
      body,
    ),
  Import: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
    body: FormData,
  ) =>
    request.post<EmailImport>(
      `email/Import?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
      body,
    ),
  LoadPrkUsers: (organizationId: number, externalId: number) =>
    request.get<PrkUserGroup | null>(
      `Email/LoadPrkUsers?organizationId=${organizationId}&externalId=${externalId}`,
    ),
  ImportPrkUsers: (
    wizardId: number,
    resultsetGroupId: number,
    prkUserGroup: PrkUserGroup,
  ) =>
    request.post<EmailImport | null>(
      `Email/importPrkUsers?wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
      prkUserGroup,
    ),
};

export default Emails;
