import { ActionTypes } from 'state/action-types';
import { Action } from 'state/actions';
import { Dispatch } from 'redux';
import agent from 'api';

export const getSurvey = (
  lang: string,
  organizationId: number,
  wizardId: number,
  resultsetGroupId: number,
) => {
  return async (dispatch: Dispatch<Action>) => {
    const Survey = await agent.Survey.get(
      lang,
      organizationId,
      wizardId,
      resultsetGroupId,
    );
    dispatch({
      type: ActionTypes.SET_SURVEY,
      payload: {
        Survey,
      },
    });
  };
};
