import { OtpTickets } from 'models';
import { Action } from 'state/actions';
import { ActionTypes } from 'state/action-types';

interface TicketsState {
  loading: boolean;
  error: string | null;
  data: {
    Tickets: OtpTickets[] | null;
  };
}

const initialState: TicketsState = {
  loading: false,
  error: null,
  data: {
    Tickets: null,
  },
};

const reducer = (
  state: TicketsState = initialState,
  action: Action,
): TicketsState => {
  switch (action.type) {
    case ActionTypes.SET_TICKETS:
      return {
        ...state,
        loading: false,
        data: {
          Tickets: action.payload.Tickets,
        },
      };
    case ActionTypes.SET_TICKETS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ActionTypes.RESET_TICKETS:
      return {
        ...state,
        loading: false,
        data: {
          Tickets: null,
        },
      };
    default:
      return state;
  }
};

export default reducer;
