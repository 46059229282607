import { ActionTypes } from 'state/action-types';
import { Action } from 'state/actions';
import { UrlQuery, User } from 'models';
import { Dispatch } from 'redux';
import localForage from 'localforage';
import agent from 'api';
import { toast } from 'react-toastify';

export const logIn = (user: User | null, userToken?: string | null) => {
  return async (dispatch: Dispatch<Action>) => {
    let User = user;
    if (userToken) {
      try {
        User = await agent.Login.getToken(userToken);
      } catch (error) {
        toast.error('An error occurred.');
      }
    }
    await localForage.setItem('User', User);
    dispatch({
      type: ActionTypes.LOGIN,
      payload: {
        User,
      },
    });
  };
};

export const setUrlQuery = (UrlQuery: UrlQuery | null) => {
  return async (dispatch: Dispatch<Action>) => {
    await localForage.setItem('UrlQuery', UrlQuery);
    dispatch({
      type: ActionTypes.SET_URL_QUERY,
      payload: {
        UrlQuery,
      },
    });
  };
};
