import { ReactElement, useState, useEffect } from 'react';
import Tab from './tab';
import TabContent from './tabContent';
import './index.scss';

interface TabInterface {
  props: {
    id: string;
    title: string;
    children: ReactElement;
  };
}

interface Props {
  activeTabId?: string;
  children: TabInterface[];
  tabListChildren?: ReactElement;
}

const Tabs = ({ activeTabId, children, tabListChildren }: Props) => {
  const [activeTab, setActiveTab] = useState<string | undefined>(
    children[0].props.id,
  );

  const onclickTabItem = (tab: string) => {
    tab && setActiveTab(tab);
  };

  const isActiveTab = (id: string) => {
    if (id) return activeTab === id;
    else return false;
  };

  useEffect(() => {
    setActiveTab(activeTabId);
  }, [activeTabId]);

  return (
    <div key={`tabs-${activeTabId}`}>
      <div className="tab-list-container">
        <ol className="tab-list">
          {children.map((child: TabInterface) => {
            return (
              <Tab
                key={`tap-key-${child.props.id}`}
                disabled={!child.props.id}
                id={child.props.id}
                activeTab={isActiveTab(child.props.id)}
                title={child.props.title}
                onClick={onclickTabItem}
              />
            );
          })}
        </ol>
        {tabListChildren && (
          <div className="tab-list-children">{tabListChildren}</div>
        )}
      </div>
      <div>
        {children.map((child: TabInterface) => {
          if (child.props.id !== activeTab)
            return (
              <div
                className="hidden-tab-content"
                key={`tab-content-${child.props.id}`}
              ></div>
            );
          return (
            <TabContent key={`tab-content-${child.props.id}`}>
              {child.props.children}
            </TabContent>
          );
        })}
      </div>
    </div>
  );
};

export { Tabs };
