import { ReactElement } from 'react';
import './index.scss';

interface Props {
  children: ReactElement;
}

const TabContent = ({ children }: Props) => {
  return <>{children}</>;
};

export default TabContent;
