import { Wizard as wd } from 'models';
import { request } from './index';

const Wizard = {
  getWizard: (wizardId: number, resultsetGroupId: number) =>
    request.get<wd | null>(
      `Wizard/WizardText?wizardId=${wizardId}&resultSetGroupId=${resultsetGroupId}`,
    ),
};

export default Wizard;
