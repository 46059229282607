import { ChangeEvent } from 'react';
import './index.scss';

interface Props {
  id?: string;
  name?: string;
  label?: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  className?: string;
}
const CheckBox = ({
  id = 'check-box',
  name,
  label,
  checked,
  onChange,
  disabled = false,
  className,
}: Props) => {
  return (
    <label
      className={`check-box-container ${disabled ? 'check-box-disabled' : ''} ${
        className ? className : ''
      }`}
    >
      <input
        className={`${disabled ? 'check-box-disabled' : ''}`}
        key={id}
        type="checkbox"
        id={id}
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <span className="checkmark"></span>
      {label && label}
    </label>
  );
};

export { CheckBox };
