import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/UI';
import './index.scss';
import { BackDrop } from 'components/UI/atoms';

interface Props {
  show: boolean;
  close: () => void;
  header?: string;
  children: ReactElement;
  save?: () => void;
  closeBtnHide?: boolean;
  saveBtnText?: string;
  customFooterBtns?: ReactElement;
}

const Modal = ({
  show,
  close,
  header,
  children,
  save,
  closeBtnHide,
  saveBtnText,
  customFooterBtns,
}: Props) => {
  const { t } = useTranslation();

  return show ? (
    <>
      <BackDrop />
      <div className="modal">
        <div
          className="modal-wrapper"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="modal-header">
            {header && <h4>{header}</h4>}
            <span className="close-modal-btn" onClick={close}>
              ×
            </span>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            {customFooterBtns ? (
              customFooterBtns
            ) : (
              <>
                <Button
                  label={saveBtnText ? saveBtnText : t('common.save')}
                  onClick={save}
                />
                {!closeBtnHide && (
                  <Button
                    btnType="primary"
                    label={t('common.close')}
                    onClick={close}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export { Modal };
