import { configdata, Organization, ParentOrganizations } from 'models';
import { Action } from 'state/actions';
import { ActionTypes } from 'state/action-types';

interface OrganizationsState {
  loading: boolean;
  error: string | null;
  data: {
    Organizations: Organization | null;
    ParentOrganizations: ParentOrganizations | null;
    configdata: configdata[] | null;
  };
}

const initialState: OrganizationsState = {
  loading: false,
  error: null,
  data: {
    Organizations: null,
    ParentOrganizations: null,
    configdata: null,
  },
};

const reducer = (
  state: OrganizationsState = initialState,
  action: Action,
): OrganizationsState => {
  switch (action.type) {
    case ActionTypes.SET_ORGANIZATIONS:
      return {
        ...state,
        loading: false,
        data: {
          Organizations: action.payload.Organizations,
          ParentOrganizations: state.data.ParentOrganizations,
          configdata: state.data.configdata,
        },
      };
    case ActionTypes.SET_PARENT_ORGANIZATIONS:
      return {
        ...state,
        loading: false,
        data: {
          Organizations: state.data.Organizations,
          ParentOrganizations: action.payload.ParentOrganizations,
          configdata: state.data.configdata,
        },
      };
    case ActionTypes.SET_CONFIG_DATA:
      return {
        ...state,
        loading: false,
        data: {
          Organizations: state.data.Organizations,
          ParentOrganizations: state.data.ParentOrganizations,
          configdata: action.payload.configdata,
        },
      };
    case ActionTypes.SET_ORGANIZATIONS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default reducer;
