import { request } from './index';
import localForage from 'localforage';
import {
  OtpTickets as OtpTickets,
  User,
  TicketsConfigData,
  TicketsExportFramWebResponse,
} from 'models';
import axios from 'axios';

const Tickets = {
  getTickets: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
  ) =>
    request.get<OtpTickets[] | null>(
      `Ticket?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
    ),
  getConfigData: () =>
    request.get<TicketsConfigData[] | null>('Ticket/ConfigData'),
  Create: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
    ticketCount: number,
    body: {},
  ) =>
    request.post<OtpTickets>(
      `Ticket/Create?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}&ticketCount=${ticketCount}`,
      body,
    ),
  DeleteBatch: (body: string[]) => request.post(`Ticket/DeleteBatch`, body),
  getSurveyUrl: () => request.get<string | null>(`Ticket/SurveyUrl`),
  updateTickets: (exportType: string, body: string[]) =>
    request.post(`Ticket/UpdateTickets?exportType=${exportType}`, body),
  ticketTagsPdf: async (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
    language: string,
    body: string[],
  ) => {
    const User = await localForage.getItem<User>('User');
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${User?.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    const responce = await axios.post<Blob>(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BASE_API_VERSION}/Ticket/TicketTagsPdf?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}&language=${language}`,
      body,
      axiosConfig,
    );
    return responce;
  },
  formPdf: async (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
    body: {
      docType: string;
      fontSize: string;
      isEvenNoOfPages: boolean;
      isPrintSmiliesEnabled: boolean;
      language: string;
      printSmilies: boolean;
      ticketIds: string[];
      languageCode: number;
    },
  ) => {
    const User = await localForage.getItem<User>('User');
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${User?.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    const responce = await axios.post<Blob>(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_BASE_API_VERSION}/Ticket/FormPdf?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
      body,
      axiosConfig,
    );

    return responce;
  },
  getTotalTicketCount: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
  ) =>
    request.get<number>(
      `Ticket/TotalTicketCount?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
    ),
  exportToFramWeb: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
    body: {},
  ) =>
    request.post<TicketsExportFramWebResponse>(
      `Ticket/exportToFramWeb?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
      body,
    ),
};

export default Tickets;
