export * from './svarut';
export * from './email';

export const TEMPLATE_SURVEY_NAME = '[besiktigelse]';
export const TEMPLATE_ORGUNIT_NAME = '[enhetsnavn]';
export const TEMPLATE_ONE_TIME_PASSWORD = '[engangspassord]';
export const TEMPLATE_LINK_TO_SYRVEY = 'LinkToSyrvey';
export const TEMPLATE_LINK_TO_SYRVEY_WITH_PASSWORD = 'LinkToSyrveyWithPassword';
export const TEMPLATE_LINK_TO_EXTRA_SYRVEY = '[exengangspassord]';
export const DEFAULT_LANGUAGE = 'nb';
