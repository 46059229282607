import moment from 'moment';
import DP, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar } from 'svg';
import './index.scss';
import nb from 'date-fns/locale/nb';
import { useEffect, useState } from 'react';
import { DEFAULT_LANGUAGE } from 'constant';
registerLocale(DEFAULT_LANGUAGE, nb);

interface Props {
  id: string;
  onChange: (date: string | null) => void;
  selectedDate?: string;
  disabled?: boolean;
  minDate?: Date;
  onCalendarOpen?: () => void;
}

const DatePicker = ({
  id,
  onChange,
  selectedDate,
  disabled = false,
  minDate,
  onCalendarOpen,
}: Props) => {
  const [newSelectedDate, setNewSelectedDate] = useState<Date | null>();
  const [fromatedDate, setFomatedDate] = useState<string>('dd.mm.åååå');

  useEffect(() => {
    if (selectedDate && minDate) {
      setNewSelectedDate(
        moment(minDate).isAfter(new Date(selectedDate))
          ? minDate
          : new Date(selectedDate),
      );
      setFomatedDate(moment(selectedDate).format('DD.MM.YYYY'));
    } else {
      setNewSelectedDate(null);
      setFomatedDate('dd.mm.åååå');
    }
  }, [minDate, selectedDate]);

  return (
    <div>
      <DP
        locale="nb"
        disabled={disabled}
        onChange={(d) => {
          if (d) {
            const date = new Date(d.toString());
            onChange(moment(date).format('YYYY-MM-DD'));
          } else {
            onChange(null);
          }
        }}
        dateFormat="dd.mm.yyyy"
        selected={newSelectedDate}
        customInput={
          <div
            className={`${disabled ? 'dateSelect-disabled' : ''} dateSelect`}
          >
            <Calendar />
            <span
              id={id}
              style={{ paddingRight: `${selectedDate ? '25px' : '5px'}` }}
            >
              {fromatedDate}
            </span>
          </div>
        }
        minDate={minDate}
        onCalendarOpen={onCalendarOpen}
        isClearable
      />
    </div>
  );
};

export { DatePicker };
