import { ActionTypes } from 'state/action-types';
import { Action } from 'state/actions';
import { UrlQuery } from 'models';
import { Dispatch } from 'redux';
import agent from 'api';
import { toast } from 'react-toastify';

export const setTicketsLoading = (loading: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_TICKETS_LOADING,
      loading: loading,
    });
  };
};

export const resetTickets = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.RESET_TICKETS,
    });
  };
};

export const setTickets = (urlQuery: UrlQuery) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_TICKETS_LOADING,
      loading: true,
    });

    try {
      const Tickets = await agent.Tickets.getTickets(
        urlQuery.selectedOrgUnitId,
        urlQuery.wizardId,
        urlQuery.resultSetGroupId,
      );
      dispatch({
        type: ActionTypes.SET_TICKETS,
        payload: {
          Tickets,
        },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_TICKETS,
        payload: {
          Tickets: null,
        },
      });
      toast.error('An error occurred.');
    }
  };
};
