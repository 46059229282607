import { User } from 'models';
import { Action } from 'state/actions';
import { ActionTypes } from 'state/action-types';

interface UserState {
  loading: boolean;
  error: string | null;
  data: {
    User: User | null;
  };
}

const initialState: UserState = {
  loading: false,
  error: null,
  data: {
    User: null,
  },
};

const reducer = (
  state: UserState = initialState,
  action: Action,
): UserState => {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return {
        ...state,
        data: {
          User: action.payload.User,
        },
      };
    default:
      return state;
  }
};

export default reducer;
