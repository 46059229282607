import { SvarUT, ActionLog } from 'models';
import { Action } from 'state/actions';
import { ActionTypes } from 'state/action-types';

interface SvarUTState {
  loading: boolean;
  error: string | null;
  data: {
    SvarUT: SvarUT[] | null;
    SvarUTActionLog: ActionLog[] | null;
  };
}

const initialState: SvarUTState = {
  loading: false,
  error: null,
  data: {
    SvarUT: null,
    SvarUTActionLog: null,
  },
};

const reducer = (
  state: SvarUTState = initialState,
  action: Action,
): SvarUTState => {
  switch (action.type) {
    case ActionTypes.SET_SVAR_UT:
      return {
        ...state,
        loading: false,
        data: {
          SvarUT: action.payload.SvarUT,
          SvarUTActionLog: state.data.SvarUTActionLog,
        },
      };
    case ActionTypes.SET_SVAR_UT_ACTION_LOG:
      return {
        ...state,
        data: {
          SvarUT: state.data.SvarUT,
          SvarUTActionLog: action.payload.SvarUTActionLog,
        },
      };
    case ActionTypes.SET_SVAR_UT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ActionTypes.RESET_SVAR_UT:
      return {
        ...state,
        loading: false,
        data: {
          SvarUT: null,
          SvarUTActionLog: null,
        },
      };
    default:
      return state;
  }
};

export default reducer;
