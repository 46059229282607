import { ChangeEvent, CSSProperties, LegacyRef, Ref } from 'react';
import InputV from './inputV';
import './index.scss';

interface Props {
  id: string;
  name?: string;
  labelClass?: string;
  label?: string;
  type?: string;
  value?: string | number;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  autoComplete?: 'on' | 'off';
  className?: string;
  onSelect?: (event: ChangeEvent<HTMLInputElement>) => void;
  reference?: Ref<HTMLInputElement> | LegacyRef<HTMLInputElement>;
  style?: CSSProperties;
}
const Input = ({
  id = 'input',
  name,
  labelClass,
  label,
  type = 'text',
  value,
  placeholder,
  onChange,
  disabled,
  onBlur,
  accept,
  autoComplete,
  className = '',
  onSelect,
  reference,
  style,
}: Props) => {
  return (
    <>
      {label && (
        <label className={`${labelClass} label`} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        ref={reference}
        disabled={disabled}
        className={`${
          disabled ? 'text-input-disabled' : ''
        } text-input ${className}`}
        type={type}
        id={id}
        name={name ? name : id}
        value={value}
        placeholder={placeholder}
        onChange={onChange && onChange}
        onBlur={onBlur && onBlur}
        accept={accept}
        autoComplete={autoComplete}
        onSelect={onSelect && onSelect}
        style={style}
      />
    </>
  );
};

export { Input };
export { InputV };
