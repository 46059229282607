interface Props {
  title: string;
}

const CogGrayIcon = ({ title }: Props) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon/cog-gray" fill="#646464">
          <path
            d="M13,10 C13,11.654 11.654,13 10,13 C8.346,13 7,11.654 7,10 C7,8.346 8.346,7 10,7 C11.654,7 13,8.346 13,10 L13,10 Z M17,11 L17,9 L14.658,8.22 C14.625,8.132 14.594,8.044 14.556,7.959 L15.657,5.757 L14.242,4.343 L12.041,5.444 C11.955,5.406 11.868,5.375 11.78,5.341 L11,3 L9,3 L8.22,5.341 C8.132,5.375 8.045,5.406 7.959,5.444 L5.758,4.343 L4.343,5.757 L5.444,7.959 C5.406,8.044 5.375,8.132 5.342,8.22 L3,9 L3,11 L5.342,11.78 C5.375,11.868 5.406,11.956 5.444,12.041 L4.343,14.243 L5.758,15.657 L7.959,14.556 C8.045,14.594 8.132,14.625 8.22,14.659 L9,17 L11,17 L11.78,14.659 C11.868,14.625 11.955,14.594 12.041,14.556 L14.242,15.657 L15.657,14.243 L14.556,12.041 C14.594,11.956 14.625,11.868 14.658,11.78 L17,11 Z"
            id="Fill-1-Copy-3"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export { CogGrayIcon };
