import { ReactElement } from 'react';
import './index.scss';

interface Props {
  id?: string;
  btnType?: string;
  label?: string;
  icon?: ReactElement;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  onBlur?: () => void;
}

const Button = ({
  id,
  label,
  btnType = 'default',
  icon,
  onClick,
  className,
  disabled = false,
  onBlur,
}: Props) => {
  return (
    <button
      disabled={disabled}
      id={id}
      className={`${className} btn btn-${btnType} ${
        disabled ? 'btn-disabled' : ''
      }`}
      onClick={onClick}
      onBlur={onBlur}
    >
      {label && label}
      {icon && icon}
    </button>
  );
};

export { Button };
