import { Editor as ED } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.scss';
import { SyntheticEvent } from 'react';

interface Props {
  onChange?: (editorState: EditorState) => void;
  value?: EditorState;
  readOnly?: boolean;
  toolbarHidden?: boolean;
  onFocus?: (e: SyntheticEvent) => void;
}

const Editor = ({
  onChange,
  value,
  readOnly = false,
  toolbarHidden = false,
  onFocus,
}: Props) => {
  return (
    <ED
      toolbarHidden={toolbarHidden}
      readOnly={readOnly}
      onEditorStateChange={onChange}
      editorState={value}
      toolbarClassName="toolbarClassName"
      editorClassName={`editorClassName ${readOnly ? 'editorReadOnly' : ''}`}
      onFocus={onFocus && onFocus}
      toolbar={{
        options: [
          'inline',
          //'blockType',
          'fontSize',
          'fontFamily',
          'list',
          'textAlign',
          'colorPicker',
          'link',
          'embedded',
          'emoji',
          'image',
          'remove',
          'history',
        ],
        inline: {
          options: [
            'bold',
            'italic',
            'underline', //'strikethrough', //'monospace', //'superscript',  //'subscript',
          ],
        },
        textAlign: {
          options: [
            'left',
            'center',
            'right',
            //'justify'
          ],
        },
      }}
    />
  );
};

export { Editor };
