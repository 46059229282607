import { ActionTypes } from 'state/action-types';
import { Action } from 'state/actions';
import { OrganizationSurvey, UrlQuery } from 'models';
import agent from 'api';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';

export const setOrganizationSurveyLoading = (loading: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_ORGANIZATION_SURVEY_LOADING,
      loading: loading,
    });
  };
};

export const getOrganizationSurvey = (urlQuery: UrlQuery) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_ORGANIZATION_SURVEY_LOADING,
      loading: true,
    });
    let OrganizationSurvey = null;
    try {
      OrganizationSurvey = await agent.OrganizationSurvey.get(
        urlQuery.selectedOrgUnitId,
        urlQuery.wizardId,
        urlQuery.resultSetGroupId,
      );
    } catch (error) {
      toast.error('An error occurred.');
    }

    dispatch({
      type: ActionTypes.SET_ORGANIZATION_SURVEY,
      payload: {
        OrganizationSurvey,
      },
    });
  };
};

const toastId = 'id-success-UpDateOrganizationSurvey';

export const UpDateOrganizationSurvey = (
  propertyName: string,
  OrgSur: OrganizationSurvey,
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_ORGANIZATION_SURVEY_LOADING,
      loading: true,
    });
    let OrganizationSurveyUpdate = null;
    try {
      OrganizationSurveyUpdate = await agent.OrganizationSurvey.updateproperty(
        propertyName,
        { ...OrgSur },
      );
      if (OrganizationSurveyUpdate?.success) {
        toast.success(`Success`, {
          toastId: toastId,
        });
      } else {
        toast.error(
          `${
            OrganizationSurveyUpdate?.errormessage
              ? 'Error: ' + OrganizationSurveyUpdate?.errormessage
              : 'An error occurred.'
          }`,
        );
      }
    } catch (error) {
      toast.error('An error occurred.');
    }

    dispatch({
      type: ActionTypes.UPDATE_ORGANIZATION_SURVEY,
      payload: {
        OrganizationSurveyUpdate,
      },
    });
  };
};
