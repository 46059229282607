interface Props {
  title: string;
}

const NetworkIcon = ({ title }: Props) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon/network" fill="#050037">
          <path
            d="M8,5 L12,5 L12,1 L8,1 L8,5 Z M8,19 L12,19 L12,15 L8,15 L8,19 Z M16,19 L20,19 L20,15 L16,15 L16,19 Z M0,19 L4,19 L4,15 L0,15 L0,19 Z M3,13 L1,13 L1,9 L9,9 L9,7 L11,7 L11,9 L19,9 L19,13 L17,13 L17,11 L11,11 L11,13 L9,13 L9,11 L3,11 L3,13 Z"
            id="network_3-[#1116]"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export { NetworkIcon };
