interface Props {
  title: string;
}

const FileDoubleIcon = ({ title }: Props) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon/fileboard_done" fill="#050037">
          <path
            d="M15,4 L14,4 L14,6 L6,6 L6,4 L5,4 L5,18 L15,18 L15,4 Z M8,4 L12,4 L12,2 L8,2 L8,4 Z M17,2 L17,20 L3,20 L3,2 L6,2 L6,0 L14,0 L14,2 L17,2 Z M12.121,8.172 L13.536,9.586 L9.293,13.828 C7.85,12.385 8.322,12.858 6.464,11 L7.879,9.586 L9.293,11 L12.121,8.172 Z"
            id="fileboard_done-[#1583]"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export { FileDoubleIcon };
