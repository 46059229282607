import { CSSProperties, useState } from 'react';

interface Props {
  style?: CSSProperties;
}

export const Logo = ({ style }: Props) => {
  // eslint-disable-next-line no-undef
  const APP_INSTANCE_ID = process.env.REACT_APP_INSTANCE_ID;
  const [logoImg, setLogo] = useState(`/logo_${APP_INSTANCE_ID}.png`);

  return (
    <img
      style={style}
      alt="KF"
      src={logoImg}
      onError={() => {
        setLogo(`/logo_${APP_INSTANCE_ID}.svg`);
      }}
    />
  );
};
