import { Emails, ActionLog } from 'models';
import { Action } from 'state/actions';
import { ActionTypes } from 'state/action-types';

interface EmailsState {
  loading: boolean;
  error: string | null;
  data: {
    Emails: Emails[] | null;
    EmailActionLog: ActionLog[] | null;
  };
}

const initialState: EmailsState = {
  loading: false,
  error: null,
  data: {
    Emails: null,
    EmailActionLog: null,
  },
};

const reducer = (
  state: EmailsState = initialState,
  action: Action,
): EmailsState => {
  switch (action.type) {
    case ActionTypes.SET_EMAILS:
      return {
        ...state,
        loading: false,
        data: {
          Emails: action.payload.Emails,
          EmailActionLog: state.data.EmailActionLog,
        },
      };
    case ActionTypes.SET_EMAILS_ACTION_LOG:
      return {
        ...state,
        data: {
          Emails: state.data.Emails,
          EmailActionLog: action.payload.EmailActionLog,
        },
      };
    case ActionTypes.SET_EMAIL_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ActionTypes.RESET_EMAIL:
      return {
        ...state,
        loading: false,
        data: {
          Emails: null,
          EmailActionLog: null,
        },
      };
    default:
      return state;
  }
};

export default reducer;
