import ClipLoader from 'react-spinners/ClipLoader';

interface Props {
  size?: number;
  margin?: string;
}

const Loader = ({ size, margin = 'auto' }: Props) => {
  return (
    <ClipLoader css={`"margin: ${margin};"`} color="#f5cea3" size={size} />
  );
};

export { Loader };
