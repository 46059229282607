export enum EmailPropertyNames {
  invitationEmailScheduledSendDate = 'invitationEmailScheduledSendDate',
  invitationEmailSubject = 'invitationEmailSubject',
  invitationEmailTemplate = 'invitationEmailTemplate',
  invitationSmsTemplate = 'invitationSmsTemplate',
  reminderEmailScheduledSendDate = 'reminderEmailScheduledSendDate',
  reminderEmailSubject = 'reminderEmailSubject',
  reminderEmailTemplate = 'reminderEmailTemplate',
  reminderSmsTemplate = 'reminderSmsTemplate',
  secondReminderEmailScheduledSendDate = 'secondReminderEmailScheduledSendDate',
  secondReminderEmailSubject = 'secondReminderEmailSubject',
  secondReminderEmailTemplate = 'secondReminderEmailTemplate',
  secondReminderSmsTemplate = 'secondReminderSmsTemplate',
}
