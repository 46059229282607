import { ActionTypes } from 'state/action-types';
import { Action } from 'state/actions';
import { UrlQuery } from 'models';
import { Dispatch } from 'redux';
import agent from 'api';
import { toast } from 'react-toastify';

export const setSvarUTLoading = (loading: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_SVAR_UT_LOADING,
      loading: loading,
    });
  };
};

export const resetSvarUT = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.RESET_SVAR_UT,
    });
  };
};

export const setSvarUT = (urlQuery: UrlQuery) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_SVAR_UT_LOADING,
      loading: true,
    });
    try {
      const SvarUT = await agent.SvarUT.getSvarUT(
        urlQuery.selectedOrgUnitId,
        urlQuery.wizardId,
        urlQuery.resultSetGroupId,
      );
      dispatch({
        type: ActionTypes.SET_SVAR_UT,
        payload: {
          SvarUT,
        },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_SVAR_UT,
        payload: {
          SvarUT: null,
        },
      });
      toast.error('An error occurred.');
    }
  };
};

export const setSvarUTActionLog = (urlQuery: UrlQuery) => {
  return async (dispatch: Dispatch<Action>) => {
    let SvarUTActionLog = null;
    try {
      SvarUTActionLog = await agent.SvarUT.getSvarUTActionLog(
        urlQuery.selectedOrgUnitId,
        urlQuery.wizardId,
        urlQuery.resultSetGroupId,
      );
    } catch (error) {
      toast.error('An error occurred.');
    }

    dispatch({
      type: ActionTypes.SET_SVAR_UT_ACTION_LOG,
      payload: {
        SvarUTActionLog,
      },
    });
  };
};
