interface Props {
  title: string;
}

const ProfileIcon = ({ title }: Props) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon/profile" fill="#050037">
          <path
            d="M10,12 C13.785,12 16.958,14.214 17.784,18 L2.216,18 C3.042,14.214 6.215,12 10,12 M6,6 C6,3.794 7.794,2 10,2 C12.206,2 14,3.794 14,6 C14,8.206 12.206,10 10,10 C7.794,10 6,8.206 6,6 M16,6 C16,2.686 13.314,0 10,0 C6.686,0 4,2.686 4,6 C4,7.89 4.876,9.574 6.242,10.673 C2.583,12.048 0,15.445 0,20 L20,20 C20,15.445 17.417,12.048 13.758,10.673 C15.124,9.574 16,7.89 16,6 Z"
            id="profile-[#1336]"
          ></path>
        </g>
      </g>
    </svg>
  );
};

const ProfilesIcon = ({ title }: Props) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon/users" fill="#050037">
          <path
            d="M10.1650391,12.0150757 C10.9560391,9.64807568 9.33,7.92 11.958,7.902 C11.972,7.902 11.985,7.906 12,7.906 C12.015,7.906 12.028,7.902 12.042,7.902 C14.671,7.92 16.899,9.632 17.69,12 L10.1650391,12.0150757 Z M12,1.906 C13.103,1.906 14,2.803 14,3.906 C14,4.995 13.125,5.879 12.042,5.902 C12.028,5.902 12.014,5.9 12,5.9 C11.986,5.9 11.972,5.902 11.958,5.902 C10.875,5.879 10,4.995 10,3.906 C10,2.803 10.897,1.906 12,1.906 L12,1.906 Z M16,3.906 C16,1.697 14.209,-0.094 12,-0.094 C9.791,-0.094 8,1.697 8,3.906 C8,4.899 8.375,5.796 8.974,6.495 C6.057,7.686 10.4811401,5.78648389 10.4811401,9.13348389 C10.4811401,9.17348389 10.483314,14.0045776 10.484314,14.0045776 L19.994,14 C19.994,14 20,13.934 20,13.893 C20,10.547 17.943,7.687 15.026,6.495 C15.625,5.796 16,4.899 16,3.906 Z"
            id="profile_minus_minus-[#1356]-copy"
          ></path>
          <path
            d="M2.312,18 C3.103,15.633 5.33,13.92 7.958,13.902 C7.972,13.902 7.985,13.906 8,13.906 C8.015,13.906 8.028,13.902 8.042,13.902 C10.671,13.92 12.899,15.632 13.69,18 L2.312,18 Z M8,7.906 C9.103,7.906 10,8.803 10,9.906 C10,10.995 9.125,11.879 8.042,11.902 C8.028,11.902 8.014,11.9 8,11.9 C7.986,11.9 7.972,11.902 7.958,11.902 C6.875,11.879 6,10.995 6,9.906 C6,8.803 6.897,7.906 8,7.906 L8,7.906 Z M11.026,12.495 C11.625,11.796 12,10.899 12,9.906 C12,7.697 10.209,5.906 8,5.906 C5.791,5.906 4,7.697 4,9.906 C4,10.899 4.375,11.796 4.974,12.495 C2.057,13.686 0,16.543 0,19.89 C0,19.93 0.005,20 0.006,20 L15.994,20 C15.994,20 16,19.934 16,19.893 C16,16.547 13.943,13.687 11.026,12.495 L11.026,12.495 Z"
            id="profile_minus_minus-[#1356]"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export { ProfileIcon, ProfilesIcon };
