import { request } from './index';
import { configdata, Organization, ParentOrganizations } from 'models';

const Organizations = {
  list: (organizationId: number) =>
    request.get<Organization | null>(
      // eslint-disable-next-line no-undef
      `organization/organizations?depth=${process.env.REACT_APP_ORGANIZATION_DEPTH}&rootorgId=${organizationId}`,
    ),
  getParentOrg: (organizationId: number) =>
    request.get<ParentOrganizations | null>(
      `Organization/ParentOrganizations?organizationid=${organizationId}`,
    ),
  configdata: () => request.get<configdata[] | null>(`organization/configdata`),
};

export default Organizations;
