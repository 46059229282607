import {
  SendEmailSmsResponse,
  SvarUtRespondent as SR,
  SvarUtRespondentResponse,
  CreateSvarUtRespondentResponse,
} from 'models';
import { request } from './index';

const SvarUtRespondent = {
  sendmail: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
    messagetype: string,
    body: string[],
  ) =>
    request.post<SendEmailSmsResponse>(
      `SvarUtRespondent/sendmail?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}&messagetype=${messagetype}`,
      body,
    ),
  Create: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
    body: SR,
  ) =>
    request.post<CreateSvarUtRespondentResponse>(
      `SvarUtRespondent/Create?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
      body,
    ),
  Import: (
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
    body: FormData,
  ) =>
    request.post<SvarUtRespondentResponse>(
      `SvarUtRespondent/Import?organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
      body,
    ),
};

export default SvarUtRespondent;
