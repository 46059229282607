import { Survey } from 'models';
import { Action } from 'state/actions';
import { ActionTypes } from 'state/action-types';

interface SurveyState {
  loading: boolean;
  error: string | null;
  data: {
    Survey: Survey | null;
  };
}

const initialState: SurveyState = {
  loading: false,
  error: null,
  data: {
    Survey: null,
  },
};

const reducer = (
  state: SurveyState = initialState,
  action: Action,
): SurveyState => {
  switch (action.type) {
    case ActionTypes.SET_SURVEY:
      return {
        ...state,
        data: {
          Survey: action.payload.Survey,
        },
      };
    default:
      return state;
  }
};

export default reducer;
