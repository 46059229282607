import { request } from './index';
import { Survey as SV } from 'models';

const Survey = {
  get: (
    lang: string,
    organizationId: number,
    wizardId: number,
    resultsetGroupId: number,
  ) =>
    request.get<SV | null>(
      `Survey?language=${lang}&organizationId=${organizationId}&wizardId=${wizardId}&resultsetGroupId=${resultsetGroupId}`,
    ),
};

export default Survey;
