import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import {
  NotFoundPage,
  UnauthorizedPage,
} from 'features/Respondents/errorPages';
import Login from 'features/Respondents/Login';
import 'react-toastify/dist/ReactToastify.min.css';
import 'styles/global.scss';
import './App.scss';

const Home = lazy(() => import('features/Respondents/Home'));
const Logout = lazy(() => import('features/Respondents/Logout'));

const App = () => {
  return (
    <>
      <Suspense fallback={<></>}>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/logout" exact component={Logout} />
          <Route path="/respondents" exact component={Home} />
          <Route path="/unauthorized" exact component={UnauthorizedPage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
        <ToastContainer hideProgressBar={true} />
      </Suspense>
    </>
  );
};

export default App;
