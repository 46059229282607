import { FocusEvent } from 'react';
import { Textbox } from 'react-inputs-validation';
import './index.scss';

interface Props {
  id: string;
  name: string;
  labelClass?: string;
  label?: string;
  type?: string;
  value: string;
  placeholder?: string;
  onChange: (name: string, value: string) => void;
  disabled?: boolean;
  onBlur?: (value: FocusEvent<HTMLElement> | null) => void;
  accept?: string;
  validate?: boolean;
  validationCallback?: (isValide: boolean) => void;
  validationOption?: {
    name?: string;
    required?: boolean;
    msgOnError?: string;
    type?: string;
    min?: number;
    max?: number;
    reg?: string;
    regMsg?: string;
    customFunc?: (value: string) => string | true | object | null | undefined;
  };
  autoComplete?: 'on' | 'off';
}
const InputV = ({
  id = 'input',
  name,
  labelClass,
  label,
  type = 'text',
  value,
  placeholder,
  onChange,
  disabled,
  onBlur,
  validate = false,
  validationCallback,
  validationOption,
  autoComplete = 'off',
}: Props) => {
  return (
    <>
      {label && (
        <label className={`${labelClass} label`} htmlFor={id}>
          {label}
        </label>
      )}
      <Textbox
        attributesInput={{
          id,
          name,
          type,
          // @ts-ignore
          autoComplete,
          placeholder,
        }}
        value={value}
        disabled={disabled}
        classNameInput="text-input"
        onChange={(v, e) => {
          onChange(e.target.id, v);
        }}
        onBlur={(e) => {
          onBlur && onBlur(e);
        }}
        validate={validate}
        validationCallback={(isValide) => {
          validationCallback && validationCallback(isValide);
        }}
        validationOption={validationOption}
      />
    </>
  );
};

export default InputV;
