import { memo } from 'react';
import './tab.scss';

interface Props {
  id: string;
  activeTab: boolean;
  title: string;
  onClick: (id: string) => void;
  disabled?: boolean;
}

const Tab = ({ id, activeTab, title, onClick, disabled }: Props) => {
  return (
    <li
      key={`tab-${id}`}
      className={`tab-list-item ${activeTab && 'tab-list-active'} ${
        disabled ? 'tab-disabled' : ''
      }`}
      onClick={() => {
        onClick(id);
      }}
    >
      <div /> {title}
    </li>
  );
};

export default memo(Tab);
