interface Props {
  title: string;
}

const ArrowInBoxIcon = ({ title }: Props) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon/arrow-in-box" fill="#050037">
          <path
            d="M14.94,4.671 L13.592,6.125 L11,3.697 L11,12.063 L9,12.063 L9,3.697 L6.456,6.125 L5.094,4.671 L10.042,0 L14.94,4.671 Z M20,20 L0,20 L0,10 L4,10 L4,12 L2,12 L2,18 L18,18 L18,12 L16,12 L16,10 L20,10 L20,20 Z"
            id="arrow_in_down-[#384]"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export { ArrowInBoxIcon };
