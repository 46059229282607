interface Props {
  title: string;
}

const QuestionIcon = ({ title }: Props) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{title}</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icon/question" fill="#050037">
          <path
            d="M9.778,14.512 C9.778,15.064 9.33,15.512 8.778,15.512 C8.226,15.512 7.778,15.064 7.778,14.512 C7.778,13.96 8.226,13.512 8.778,13.512 C9.33,13.512 9.778,13.96 9.778,14.512 L9.778,14.512 Z M5.778,5 C5.778,4.448 6.226,4 6.778,4 L12.488,4 C13.323,4 14,4.677 14,5.512 L14,8 C14,9.1 13.1,10 12,10 L10.509,10 C10.228,10 10,10.228 10,10.509 L10,11.512 C10,12.064 9.553,12.512 9,12.512 C8.448,12.512 8,12.064 8,11.512 L8,9.778 C8,8.796 8.796,8 9.778,8 L10.778,8 C11.33,8 11.778,7.552 11.778,7 C11.778,6.448 11.33,6 10.778,6 L6.778,6 C6.226,6 5.778,5.552 5.778,5 L5.778,5 Z M2,18 L18,18 L18,2 L2,2 L2,18 Z M0,20 L20,20 L20,0 L0,0 L0,20 Z"
            id="question-[#1444]"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export { QuestionIcon };
